import { ComponentPropsWithoutRef } from "react"
import s from "./Button.module.css"

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: "default" | "danger"
}

const Button = (props: ButtonProps) => {
  const { children, variant = "default", className, ...buttonProps } = props
  return (
    <button
      {...buttonProps}
      className={`${s.button} ${variant === "danger" ? s.danger : ""}`}
    >
      {children}
    </button>
  )
}

export default Button

interface ButtonLinkProps extends ComponentPropsWithoutRef<"a"> {
  variant?: "default" | "danger"
}

export const ButtonLink = (props: ButtonLinkProps) => {
  const { children, variant = "default", className, ...buttonProps } = props
  return (
    <a
      {...buttonProps}
      className={`${s.button} ${variant === "danger" ? s.danger : ""}`}
    >
      {children}
    </a>
  )
}
